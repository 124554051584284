/**
 * 审核状态
 */
export const EXAMINE_STATUS_ENUM = {
  // all: {
  //   value: "",
  //   label: "全部",
  // },
  0: {
    value: 0,
    label: "未审批",
  },
  1: {
    value: 1,
    label: "已审批",
  },
  2: {
    value: 2,
    label: "已作废",
  },
  3: {
    value: 3,
    label: "已驳回",
  },
  4: {
    value: 4,
    label: "已通过",
  },
};
