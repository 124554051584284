var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            center: "",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "mt-10", staticStyle: { width: "80%" } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.personForm,
                    rules: _vm.rules,
                    "label-width": "20%",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货号", prop: "sku_code" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入货号",
                          disabled: !_vm.isEdit,
                        },
                        model: {
                          value: _vm.personForm.sku_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.personForm, "sku_code", $$v)
                          },
                          expression: "personForm.sku_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "件数", prop: "quantity" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入件数" },
                        model: {
                          value: _vm.personForm.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.personForm, "quantity", $$v)
                          },
                          expression: "personForm.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remarks" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          placeholder: "输入备注",
                          maxlength: "200",
                          "show-word-limit": "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.personForm.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.personForm, "remarks", $$v)
                          },
                          expression: "personForm.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片证据", prop: "image_list" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          class: { hide: _vm.pic.length == 5 },
                          attrs: {
                            "on-preview": _vm.handlePictureCardPreview,
                            action: `${_vm.BASE.PRO2}/upload`,
                            headers: { token: _vm.token },
                            accept: ".jpg,.jpeg,.png",
                            "on-success": _vm.successFile,
                            "on-error": _vm.errorFile,
                            "before-remove": _vm.beforeRemove,
                            "on-exceed": _vm.onExceed,
                            "on-change": _vm.onChange,
                            "list-type": "picture-card",
                            multiple: "",
                            "file-list": _vm.fileList,
                            limit: 5,
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { slot: "trigger" },
                            slot: "trigger",
                          }),
                        ]
                      ),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v("【" + _vm._s(_vm.pic.length || 0) + "/5】"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "video_list", label: "视频证据" } },
                    [
                      _c(
                        "el-upload",
                        {
                          class: { hide: _vm.video.length == 2 },
                          attrs: {
                            action: `${_vm.BASE.PRO2}/upload`,
                            headers: { token: _vm.token },
                            accept: ".mp4,.avi,.flv,.mov",
                            "on-success": _vm.successFile1,
                            "on-error": _vm.errorFile1,
                            "before-remove": _vm.beforeRemove1,
                            "on-exceed": _vm.onExceed1,
                            "on-change": _vm.onChange1,
                            "list-type": "picture-card",
                            multiple: "",
                            "show-file-list": false,
                            limit: 2,
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { slot: "trigger" },
                            slot: "trigger",
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "d-flex" }, [
                        _vm.video.length
                          ? _c(
                              "section",
                              { staticClass: "video-wrap" },
                              _vm._l(_vm.video, function (file, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "video-child" },
                                  [
                                    _c("video", {
                                      ref: "videoPlayer",
                                      refInFor: true,
                                      staticClass: "video pointer",
                                      attrs: {
                                        src: file,
                                        controls: true,
                                        autoplay: true,
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "handle-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onHandleRemoveFile(
                                              file,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon el-icon-delete",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _c("span", { staticStyle: { "margin-left": "20px" } }, [
                          _vm._v("【" + _vm._s(_vm.video.length || 0) + "/2】"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showImage },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "showImg",
            attrs: { src: _vm.bigImage, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }