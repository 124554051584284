var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "tooltip-effect": "dark",
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "sku_code",
          width: "100px",
          align: "center",
          label: "货号",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "supplier_name", align: "center", label: "供应商名称" },
      }),
      _c("el-table-column", {
        attrs: { prop: "create_time", align: "center", label: "添加时间" },
      }),
      _c("el-table-column", {
        attrs: { prop: "owner_name", align: "center", label: "添加人" },
      }),
      _c("el-table-column", {
        attrs: { prop: "quantity", align: "center", label: "件数" },
      }),
      _c("el-table-column", {
        attrs: { prop: "deduction_amt", align: "center", label: "扣款金额" },
      }),
      _c("el-table-column", {
        attrs: { prop: "video_list", align: "center", label: "视频证据" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "pointer font-weight",
                    staticStyle: { color: "#1389ae" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleShow(scope.row)
                      },
                    },
                  },
                  [_vm._v("查看证据")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "remarks",
          align: "center",
          label: "备注",
          width: "300",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "operate_name", align: "center", label: "审核人" },
      }),
      _c("el-table-column", {
        attrs: { prop: "state_txt", align: "center", label: "审核状态" },
      }),
      _c("el-table-column", {
        attrs: { prop: "deduction_time", align: "center", label: "扣款时间" },
      }),
      _c("el-table-column", {
        attrs: {
          label: "操作",
          align: "center",
          fixed: "right",
          "min-width": "130",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.state == _vm.EXAMINE_STATUS_ENUM["0"].value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onEditGood(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
                _vm.is_audit &&
                scope.row.state == _vm.EXAMINE_STATUS_ENUM["0"].value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onToExamine(scope.row)
                          },
                        },
                      },
                      [_vm._v("审核")]
                    )
                  : _vm._e(),
                scope.row.state == _vm.EXAMINE_STATUS_ENUM["0"].value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onCancel(scope.row)
                          },
                        },
                      },
                      [_vm._v("作废")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }