import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO2;

/**
 * 全民品控上报列表
 * @param {*} params
 */
export const postFleetQualityControlList = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/quality/control/list`, params);
};

/**
 * 修改提交数据
 * @param {*} params
 */
export const postFleetQualityControlUpdate = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/quality/control/update`, params);
};

/**
 * 审核
 * @param {*} params
 */
export const postFleetQualityControlAudit = (params = {}) => {
  return axios.post(`${BASE_URL}/fleet/quality/control/audit`, params);
};
